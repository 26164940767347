import { format } from 'date-fns'
import { nlBE } from 'date-fns/locale'

import cloneDeep from 'lodash/cloneDeep'
import DIAL_CODES from '@/utils/dialCodes'

import { errorModal } from '@/modalMessages'
import { exportAsCsv } from '@/services/exports'
import { getJobStatus } from '@/services/apiService'

export async function sleep (time) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, time)
  })
}

// Chunking functions - start
export function all (items, fn) {
  const promises = items.map(item => fn(item))
  return Promise.all(promises)
}

export function series (items, fn) {
  const result = []
  return items
    .reduce((acc, item) => {
      acc = acc.then(() => {
        return fn(item).then(res => result.push(res))
      })
      return acc
    }, Promise.resolve())
    .then(() => result)
}

export function splitToChunks (items, chunkSize = 50) {
  const result = []
  for (let i = 0; i < items.length; i += chunkSize) {
    result.push(items.slice(i, i + chunkSize))
  }
  return result
}

export function chunks (items, fn, chunkSize = 50) {
  let result = []
  const chunks = splitToChunks(items, chunkSize)
  return series(chunks, chunk => {
    return all(chunk, fn).then(res => (result = result.concat(res)))
  }).then(() => result)
}
// Chunking functions - end

export function formatContact (contactData) {
  const contact = cloneDeep(contactData)

  if (contact.city?.id) contact.city = contact.city.id
  if (contact.street?.id) contact.street = contact.street.text
  if (contact.birth_place?.id) contact.birth_place = contact.birth_place.id

  contact.birth_date = contact.birth_date
    ? format(new Date(contact.birth_date), 'yyyy-MM-dd')
    : null

  return contact
}

export function rgba2hex (rgba) {
  return `#${rgba.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+\.{0,1}\d*))?\)$/).slice(1).map((n, i) => (i === 3 ? Math.round(parseFloat(n) * 255) : parseFloat(n)).toString(16).padStart(2, '0').replace('NaN', '')).join('')}`
}

export function hexToRgb (hex) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b
  })

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null
}

export function currency (amount) {
  if (!amount && amount !== 0) return '-'
  return new Intl.NumberFormat('nl', {
    style: 'currency',
    currency: 'EUR'
  }).format(amount)
}

export function percentage (number) {
  if (!number && number !== 0) return '-'
  return new Intl.NumberFormat('nl', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  }).format(number / 100)
}

export function addDaysText (numberOfDays) {
  return numberOfDays ? numberOfDays + ' jours' : '-'
}

export function formatHHmm (datetime) {
  return format(new Date(datetime), 'HH:mm')
}

export function formatDateForLocale (date, monthInFull = true) {
  const options = {
    day: 'numeric',
    month: monthInFull ? 'long' : 'numeric',
    year: 'numeric'
  }

  return date ? new Date(date).toLocaleDateString('nl', options) : '-'
}

const todayMilliseconds = new Date().getTime()

export function toCalendarDate (milliseconds) {
  return new Date(milliseconds).toISOString().split('T')[0] // Returns the date in YYYY-MM-DD format
}

export function getFutureDate (days) {
  const todayPlusDays = todayMilliseconds + (days * 86_400_000)
  return toCalendarDate(todayPlusDays)
}

export const today = {
  date: toCalendarDate(todayMilliseconds),
  forValidation: toCalendarDate(todayMilliseconds + 86_400_000) // Today + 1 day(to allow today)
}

export const minEighteenYears = {
  date: toCalendarDate(todayMilliseconds - 568_036_800_000), // Today - 18 years in ms
  forValidation: toCalendarDate(
    todayMilliseconds - 568_036_800_000 + 86_400_000 // Today - 18 years in ms + 1 day(to allow the 18th year's date)
  )
}

export function daysBetweenDates (date1, date2) {
  const date1Milliseconds = date1.getTime()
  const date2Milliseconds = date2.getTime()
  const differenceMilliseconds = date1Milliseconds - date2Milliseconds
  return Math.floor(differenceMilliseconds / (1000 * 60 * 60 * 24))
}

export function formatPhone (dialCode, number) {
  number = number.replace(/[^\d]/g, '') // Removes everything that isn't a number
  if (!number) return ''

  number = number.charAt(0) === '0'
    ? number.replace(/^0+/, '') // Removes the leading zero
    : number
  return dialCode + number
}

export const DEFAULT_DIAL_CODE = '+32'

export function parsePhoneNumber (value) {
  let dialCode = DEFAULT_DIAL_CODE
  let number = value
  // When using this helper function in the ContactConnector component
  // 3CX (our telephone system) will send international phone numbers
  // starting with a double zero. For the parsing to work correctly
  // we need to convert this leading zeros into a plus sign
  if (value?.substring(0, 2) === '00') value = `+${value.slice(2)}`

  if (value?.includes('+')) {
    if (value.slice(0, 3) === DEFAULT_DIAL_CODE) number = value.slice(3)
    else {
      const found = DIAL_CODES.find(el => value.includes(el.code))
      if (found?.code) {
        dialCode = found.code
        number = value.slice(dialCode.length)
      } else number = value.slice(1) // Remove the plus sign from the number
    }
  }

  return { dialCode, number }
}

export function blockPeriodInput (event) {
  if (event.key === '.') event.preventDefault()
}

export const USER_LANG_OPTIONS = {
  nl: 'Nederlands',
  en: 'Engels',
  fr: 'Frans'
}

export function validEndDate ({ getFormValues }) {
  const values = getFormValues()
  if (!values.end_date) return true

  const startDate = new Date(values.start_date)
  const endDate = new Date(values.end_date)

  // To compare only dates and prevent side-effects due to hh:mm:ss:ms
  startDate.setHours(0, 0, 0, 0)
  endDate.setHours(0, 0, 0, 0)
  return startDate.getTime() <= endDate.getTime()
}

export function poll (id, action, interval = 1000, payload = null, callback = null) {
  return new Promise((resolve, reject) => {
    let returnValue = null
    let status = 0
    const poll = window.setInterval(async () => {
      if (!status) {
        try {
          const result = payload ? await action(id, payload) : await action(id)
          status = result.data?.status
          returnValue = result.data
          if (callback) {
            callback(result.data) // To take action on returned result, without waiting for the entire poll to succeed.
          }
        } catch (error) {
          clearInterval(poll)
          reject(new Error(`Something went wrong during polling: ${error}`))
        }
      } else {
        clearInterval(poll)
        if (status === 1) {
          resolve(returnValue)
        } else {
          reject(
            new Error(
              `Job with uuid ${id} failed with status ${status}`,
              { cause: returnValue }
            )
          )
        }
      }
    }, interval)
  })
}

export function validateKeys (obj, objName, keys) {
  // DEPRECATED: Old helper used by the old Table component, please avoid using this.
  let valid = true
  const properties = Object.keys(obj)
  for (const property of properties) {
    if (!(keys.includes(property))) {
      console.error(`${objName} contains invalid property '${property}'`)
      valid = false
    }
  }
  return valid
}

export function getCookie (cookieName) {
  const cookie = {}
  document.cookie.split(';').forEach(function (el) {
    const [key, value] = el.split('=')
    cookie[key.trim()] = value
  })
  return cookie[cookieName]
}

export function formatDateTime (date) {
  return date ? format(new Date(date), 'dd-MM-yyyy HH:mm') : ''
}

export function formatDateNlBe (date, formatString = 'do MMMM yyyy, HH:mm') {
  return format(new Date(date), formatString, { locale: nlBE })
}

export function getTagTextColor (hex) {
  if (!hex) return '#676A6C'
  // The threshold of 186 is based on theory, but can be adjusted to taste. https://stackoverflow.com/a/3943023
  const { r, g, b } = hexToRgb(hex)
  return (r * 0.299 + g * 0.587 + b * 0.114) > 186 ? '#676A6C' : '#FFFFFF'
}

export function getUrlParams () {
  const historyUrl = document.location.href.replace(/\/#/g, '') // To parse and remove any '#' from the URL.
  return new URL(historyUrl).searchParams
}

export function getUrlParam (name) {
  const params = getUrlParams()
  return params.get(name)
}

export function validateEmail (email) {
  const re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/g
  return re.test(email)
}

export function parseFileObj (file) {
  // For formulate form usage, you will need to further wrap the response in an array
  if (!file) return false
  // Formulate input requires filename and url
  const { id, filename, url } = file
  return { id, name: filename, url }
}

export const BLANK_HMODH = {
  types: [],
  niches: [],
  bathrooms_max: null,
  bathrooms_min: null,
  bedrooms_max: null,
  bedrooms_min: null,
  build_year_from: null,
  build_year_till: null,
  garage: 0,
  garden: 0,
  is_newly_built: false,
  pets_allowed: 0,
  price_max: null,
  price_min: null,
  property_type: 3,
  restrooms_max: null,
  restrooms_min: null,
  is_investment: false,
  return_on_investment_min: null,
  return_on_investment_max: null,
  sale_with_shares: 2,
  is_rented: false,
  source: 1,
  status: 2,
  surface_plot_max: null,
  surface_plot_min: null,
  surface_trading_max: null,
  surface_trading_min: null,
  surface_livable_min: null,
  surface_livable_max: null,
  swimming_pool: 0,
  terrace: 0,
  sea_view: 0,
  elevator: 0,
  light_street: 0,
  rolling_bridge: 0,
  furnished: 0,
  bike_storage: 0,
  free_height_min: null,
  free_height_max: null,
  loading_docks_min: null,
  loading_docks_max: null,
  kva_min: null,
  kva_max: null,
  usable_office_min: null,
  usable_office_max: null,
  usable_storage_min: null,
  usable_storage_max: null,
  zipcodes: [],
  geo_search: [],
  building_types: [],
  sales_concept: 0,
  is_luxury: false,
  parking_spots_min: null,
  parking_spots_max: null
}

export const CHECKLIST_COLUMN_TITLES = {
  to_do: 'Nog te doen',
  in_progress: 'In uitvoering',
  done: 'Afgewerkt'
}

export function getContactAddress (contact) {
  // Removes unnecessary whitespace and outputs a valid address, always.
  const { street, number, box, zip_code, city } = contact
  return [
    [street, number, box].filter(el => el).join(' '),
    [zip_code, city].filter(el => el).join(' ')
  ].filter(el => el).join(', ')
}

export async function exportAsCsvHelper (payload) {
  // Eg payload: { search_params, type: 'costs' }
  const response = await exportAsCsv(payload)
  const { job_id } = response.data
  const pollResult = await poll(job_id, getJobStatus)
  const win = window.open(pollResult?.url, '_blank')
  if (!win) errorModal('Sta pop-ups toe om het document te zien.')
  return pollResult
}
